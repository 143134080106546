import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { CommonAButton } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const ActionWrapper = styled.div`
  /** Common Css **/
  ${SharedCss}

  text-align: center;

  /* TAB */
  @media (min-width: 768px) {
    display: flex;
  }
`;

const ActionCol = styled.div`
  body & {
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    color: #fff;
    /* TAB */
    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1024px) {
      padding: 100px 0;
    }
  }
`;

const ActionFree = styled(ActionCol)`
  background: #67bcd3;
`;

const ActionTitle = styled.h2`
  body & {
    font-weight: 300;

    /* TAB */
    @media (min-width: 1024px) {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }
`;

const ActionDisc = styled.p`
  body & {
    margin-bottom: 20px;

    /* TAB */
    @media (min-width: 1024px) {
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 1.8;
    }
  }
`;

const ActionButton = styled(CommonAButton)`
  body & {
    /* TAB */
    margin-top: auto;
    @media (min-width: 1024px) {
      width: 400px;
      font-size: 24px;
      line-height: 100px;
      color: #000;
      border-radius: 50px;

      &:link {
        color: #000;
      }
    }
  }
`;

const CalendlyButton = styled.button`
  body & {
    display: block;
    width: 400px;
    margin: auto auto 0;
    font-size: 24px;
    line-height: 100px;
    color: #0093bb;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #fff;
    border: none;
    border-radius: 50px;
    /* PC */
    @media (min-width: 1024px) {
      width: 400px !important;
    }
  }
`;

const ActionContact = styled(ActionCol)`
  body & {
    background: #0093bb;
  }
`;

const WOS_DATA_QUERY = graphql`
  query ActionData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-actions" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            direction
            trialInfo {
              title
              desc
              linkTitle
              linkHref
            }
            contactInfo {
              title
              desc
              linkTitle
              linkHref
              calendlyButton
            }
          }
        }
      }
    }
  }
`;

type Props = {
  calendlyModalIsOpen?: boolean;
  updateCalendlyModalIsOpen?: (bool: boolean) => void;
};

const Actions: React.FC<Props> = ({
  calendlyModalIsOpen,
  updateCalendlyModalIsOpen,
}) => {
  const data = useStaticQuery<GatsbyTypes.ActionDataQuery>(
    WOS_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const direction = frontmatter?.direction;
  const trialInfo = frontmatter?.trialInfo;
  const contactInfo = frontmatter?.contactInfo;

  return (
    <ActionWrapper
      css={`
        flex-direction: ${direction === 'rtl' ? 'row-reverse' : 'initial'};
      `}
    >
      <ActionFree>
        <ActionTitle>{trialInfo?.title}</ActionTitle>
        <ActionDisc>
          <Nl2Br text={trialInfo?.desc} />
        </ActionDisc>
        <ActionButton href={trialInfo?.linkHref}>
          {trialInfo?.linkTitle}
        </ActionButton>
      </ActionFree>
      <ActionContact>
        <ActionTitle>{contactInfo?.title}</ActionTitle>
        <ActionDisc>
          <Nl2Br text={contactInfo?.desc} />
        </ActionDisc>
        {contactInfo?.calendlyButton ? (
          <CalendlyButton
            onClick={() => {
              updateCalendlyModalIsOpen?.(!calendlyModalIsOpen);
            }}
          >
            {contactInfo?.linkTitle}
          </CalendlyButton>
        ) : (
          <ActionButton
            href={contactInfo?.linkHref}
            target="_blank"
            rel="noreferrer"
          >
            {contactInfo?.linkTitle}
          </ActionButton>
        )}
      </ActionContact>
    </ActionWrapper>
  );
};

export default Actions;
